<template>
  <div class="modal-md rounded-lg bg-white p-4 md:p-6">
    <div class="my-6 mt-2 text-center text-xl font-semibold">
      {{
        data.isAttemptPayment
          ? 'Make a payment'
          : data.fromUnpause
            ? `Unpause subscription`
            : "Cool cats can't go hungry..."
      }}
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="forwardSuccess" key="food-success-message">
        <div
          class="flex flex-grow flex-row space-x-4 space-y-4 space-y-reverse"
        >
          <div class="flex flex-row items-center">
            <div class="h-full w-2 flex-none rounded bg-orange-300"></div>
          </div>

          <div class="flex-grow">
            <div class="font-semibold">Whooo... Your box is on its way!</div>

            <div class="text-sm text-gray-700">
              Your order is now in our Packing &amp; Delivery process. Any
              changes you make will be reflected in your next box.
            </div>
            <div class="mt-4 flex flex-none text-left text-sm">
              <div class="flex-grow" v-if="!forwardSuccessUnknown">
                <div class="font-semibold">#{{ latestOrder.invId }}</div>
                <div class="text-xs text-gray-600">
                  ordered {{ latestOrder.createdAt | timeago }}
                </div>
              </div>
              <div>
                <div class="relative flex-grow">
                  <font-awesome-icon
                    class="rotate-45 transform text-4xl text-orange-300"
                    :icon="['fal', 'bell-on']"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mb-6 px-2" v-if="!data.isAttemptPayment">
          Need your food a little sooner? No worries... just make sure you've
          made any adjustments to your box, if you need to!
          <router-link
            :to="{
              name: 'adjust pricing',
              params: { plans: data }
            }"
            class="text-gray-700 underline"
            ><span @click="close">Adjust my order &rarr;</span></router-link
          >
        </div>
        <div class="space-y-4">
          <div class="flex w-full flex-col divide-y-2 px-2">
            <div class="space-y-1 py-2 text-gray-700">
              <div class="flex space-x-2">
                <div class="flex-grow">Monthly Box</div>
                <div class="">
                  {{ data.settings.originalSubscription | formatPounds }}
                </div>
              </div>

              <div
                class="flex space-x-2"
                v-if="data.bonusFood.length > 0 && !data.areSamplesFree"
              >
                <div class="flex-grow">Samples</div>
                <div class="">
                  {{ data.settings.finalExtras | formatPounds }}
                </div>
              </div>
              <div class="flex space-x-2" v-if="data.deliveryTypeIsPaid">
                <div class="flex-grow">Delivery</div>
                <div class="">
                  <span class="space-x-1">
                    <span
                      :class="{
                        'font-normal italic line-through': deliveryDiscount
                      }"
                      >{{ data.settings.originalDelivery | formatPounds }}</span
                    ><span v-if="deliveryDiscount">{{
                      data.settings.finalDelivery | formatPounds
                    }}</span>
                  </span>
                </div>
              </div>
              <div
                class="flex space-x-2"
                v-if="data.coupons.length > 0 && filteredCoupons.length > 0"
              >
                <div class="flex-grow">{{ filteredCoupons[0].display }}</div>
                <div class="">
                  <span class="space-x-1">
                    - {{ couponsAmount | formatPounds }}
                  </span>
                </div>
              </div>
              <div class="flex space-x-2" v-if="data.settings.credit">
                <div class="flex-grow">Credit</div>
                <div class="">
                  <span class="space-x-1">
                    - {{ data.settings.credit | formatPounds }}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div class="flex space-x-2 py-2 font-medium">
                <div class="flex-grow">Total</div>
                <div class="">
                  {{ data.settings.total | formatPounds }}
                </div>
              </div>
            </div>
          </div>
          <p class="px-2" v-if="!data.isAttemptPayment">
            {{
              data.fromUnpause
                ? `By unpausing your subscription:`
                : 'By requesting your food early:'
            }}
          </p>
          <div
            v-if="data.payday"
            class="flex items-start rounded bg-orange-100 p-3 px-4"
          >
            <div class="pr-4">
              <font-awesome-icon
                class="mt-1 text-xl text-orange-500"
                :icon="['far', 'circle-exclamation']"
              />
            </div>
            <div>
              Your payment date will revert to being
              <span class="font-semibold">every 28 days</span>, rather than the
              {{ paydayDate }} of every month.
            </div>
          </div>
          <div class="flex items-start rounded bg-orange-100 p-3 px-4">
            <div class="pr-4">
              <font-awesome-icon
                class="mt-1 text-xl text-orange-500"
                :icon="['far', 'circle-exclamation']"
              />
            </div>
            <div>
              We will charge
              <span class="font-semibold">
                {{ data.settings.total | formatPounds }}</span
              >

              to your payment card today.
            </div>
          </div>
          <div class="flex items-start rounded bg-orange-100 p-3 px-4">
            <div class="pr-4">
              <font-awesome-icon
                class="mt-1 text-xl text-orange-500"
                :icon="['far', 'circle-exclamation']"
              />
            </div>
            <div>
              Your order will be prepared and dispatched soon and should be with
              you in
              <span class="font-semibold">1-3 working days.</span>
            </div>
          </div>

          <div class="mb-4 mt-8 px-2">
            If you are happy with this please enter your password to confirm.
          </div>
          <div
            v-if="hasError"
            class="error-msg mb-4 space-y-2 rounded border-2 border-red-400 bg-red-100 p-4 py-3 text-sm text-red-400"
          >
            <div>
              Hmmmm something went wrong... We couldn't successfully take
              payment.
            </div>
            <div class="-ml-4 -mr-4 px-1 px-4 py-2 font-medium">
              <div class="border-l-2 border-red-400 pl-2">
                {{ error.message }}
              </div>
            </div>
            <div class="text-xs italic" v-if="error.code">
              If you continue to have trouble please contact us quoting the
              following error code:<span class="font-mono">
                {{ error.code }}</span
              >
            </div>
          </div>

          <div class="">
            <form @submit.prevent="submitForward" novalidate="true">
              <password-input
                v-model="password"
                :isFocused="hasError"
                :canView="true"
              />
              <loading-button
                :is-loading="isLoading"
                :is-enabled="formValid && !isLoading"
                :is-full="true"
                class="mt-4"
                :class="isLoading ? 'pointer-events-none' : ''"
                :caution="data.isAttemptPayment"
                :label="data.isAttemptPayment ? 'Make payment' : 'Confirm'"
              />
            </form>
          </div>
        </div>
      </div>
    </transition>
    <div class="mt-6 text-center text-gray-600">
      <a @click="closeModal">{{ forwardSuccess ? 'Close' : 'Cancel' }}</a>
    </div>
  </div>
</template>

<script>
import PasswordInput from '@/components/auth/PasswordInput';
import LoadingButton from '@/components/LoadingButton';
import utils from '@/utils';
import http from '@/http';
import { mapGetters } from 'vuex';
export default {
  props: ['data'],
  components: {
    PasswordInput,
    LoadingButton
  },
  computed: {
    formValid: function () {
      if (this.password.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    hasDeliveryCost() {
      return this.data?.deliveryTypeIsPaid;
    },
    coupons() {
      return utils.formatCoupons(this.data.coupons);
    },
    filteredCoupons() {
      return this.data?.coupons.filter((o) => o.coupon !== 'del_free');
    },
    deliveryDiscount() {
      console.log(
        this.data.settings.originalDelivery,
        this.data.settings.finalDelivery,
        this.data.settings.originalDelivery !== this.data.settings.finalDelivery
      );
      return (
        this.data.settings.originalDelivery !== this.data.settings.finalDelivery
      );
    },
    couponsAmount() {
      const subDiff =
        this.data?.settings?.originalSubscription -
        this.data?.settings?.afterCouponSubscription;
      return subDiff;
    },

    // creditAmount() {
    //   const deliveryCost = this.data?.deliveryTypeIsPaid
    //     ? this.data.isRemote
    //       ? 299
    //       : 199
    //     : 0;
    //   // const delivery = this.data.deliveryType.prices.find(
    //   //   o => o.id === this.data.deliveryTypeSelected
    //   // );
    //   let creditValue = this.data.totalCost - this.data.credit;
    //   if (this.data.credit > this.data.totalCost - deliveryCost) {
    //     creditValue = this.data.totalCost - deliveryCost - this.data.credit;
    //   }

    //   if (creditValue < 0) {
    //     return utils.getDisplayCost(0, 0, true);
    //   } else {
    //     return utils.getDisplayCost(creditValue, deliveryCost, true);
    //   }
    // },
    creditDelivery() {
      const subCost = this.data.totalCost - this.hasDeliveryCost.cost;
      const creditValue = subCost - this.data.credit;

      const delVal = this.hasDeliveryCost.cost - creditValue * -1;
      if (delVal < this.hasDeliveryCost.cost) {
        if (this.hasDeliveryCost.cost - creditValue * -1 <= 0) {
          return 'FREE';
        } else {
          return utils.getDisplayCost(
            this.hasDeliveryCost.cost,
            creditValue * -1,
            true
          );
        }
      }
      return false;
    },
    paydayDate() {
      const date = new Date(this.data.nextbilldatefull).getDate();
      return date + this.$options.filters.ordinal(date);
    },
    ...mapGetters({
      latestOrder: 'latestOrder'
    }),
    formattedSubscriptionCost() {
      const deliveryCost = this.data?.deliveryTypeIsPaid
        ? this.data.isRemote
          ? 299
          : 199
        : 0;
      // const delivery = this.data.deliveryType.prices.find(
      //   o => o.id === this.subscription.deliveryTypeSelected
      // );
      return utils.getDisplayCost(this.data.totalCost, deliveryCost, true);
    }
  },
  data() {
    return {
      password: '',
      stripe: null,
      isLoading: false,
      hasError: false,
      error: { message: '', code: '' },
      forwardSuccess: false,
      activeDelivery: null,
      forwardSuccessUnknown: false
    };
  },
  methods: {
    trigger3ds(response, method) {
      this.$store.dispatch('sendMpEvent', {
        event: 'Food Forward - Showing 3DS',
        properties: {
          location: this.$route.name,
          type: 'EVT'
        }
      });

      const stripe = this.stripe;
      const self = this;
      stripe
        .confirmCardPayment(response, {
          payment_method: method
        })
        .then(function (result) {
          if (result.error) {
            if (result?.error?.payment_intent?.last_payment_error?.message) {
              const hasError = result.error.payment_intent.last_payment_error;
              if (hasError.code === 'payment_intent_authentication_failure') {
                self.error.message =
                  'We were unable to authenticate your payment method. Please try again';
              } else {
                self.error.message = hasError.message;
              }
            } else {
              self.error.message =
                'We were unable to authenticate your payment method. Please try again';
            }

            self.hasError = true;
            self.isLoading = false;
            self.$store.dispatch('sendMpEvent', {
              event: 'Food Forward - 3DS Fail',
              properties: {
                location: self.$route.name,
                error: result.error.message,
                type: 'EVT'
              }
            });
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              self.triggerSuccess({});
            }
          }
        });
    },
    triggerSuccess(response) {
      let data = response;
      data.forward = true;
      const lastestId = this.latestOrder.invId;

      setTimeout(() => {
        this.$store.dispatch('getOrderHistory').then((response) => {
          console.log(response.data.content[0].invId, lastestId);
          const responseId = response.data.content[0].invId;
          if (responseId !== lastestId) {
            this.$store.dispatch('sendMpEvent', {
              event: 'Food Forward - Success',
              properties: {
                location: this.$route.name,
                amount: this.data.totalDisplayCost,
                type: 'EVT'
              }
            });
            this.isLoading = false;
            this.forwardSuccess = true;
          } else {
            this.isLoading = false;
            this.forwardSuccess = true;
            this.forwardSuccessUnknown = true;
            // this.password = '';
            // this.error.message =
            //   'Please try again or contact us at team@thecoolcatclub.com';
            // this.error.code = 'unknown_decline';
            // this.hasError = true;
            // this.$store.dispatch('sendMpEvent', {
            //   event: 'Food Forward - Error',
            //   properties: {
            //     location: this.$route.name,
            //     error: 'Endpoint 200',
            //     type: 'EVT'
            //   }
            // });
          }

          this.$store.dispatch('getSubscription');
        });
      }, 3000);

      // this.$store.commit('update_subscription', data.data.content[0]);
    },
    submitForward: function () {
      this.isLoading = true;
      this.hasError = false;

      http
        .post('/subscripton/chargenowpoll', {
          password: this.password,
          convert: true // Decided wether to revert them to a 30 day or keep fixed payement true = 30 days false = fixed
        })
        .then((response) => {
          if (response.data.content[0].secret) {
            this.trigger3ds(response.data.content[0].secret);
          } else {
            this.triggerSuccess({});
          }
        })
        .catch((error) => {
          const errorType = error.response.data.error.type;

          if (errorType === 'authentication_required') {
            this.trigger3ds(
              error.response.data.error.detail,
              error.response.data.error.method
              // 'pm_card_authenticationRequired'
            );
          } else {
            this.isLoading = false;
            this.hasError = true;
            this.password = '';

            this.error.message = error.response.data.error.message;
            if (error.response.data.error.type) {
              this.error.code =
                error.response.data.error.type || 'unknown_decline';
            }

            this.$store.dispatch('sendMpEvent', {
              event: 'Food Forward - Error',
              properties: {
                location: this.$route.name,
                error: error.response.data.error.message
                  ? error.response.data.error.message
                  : 'Unknown Error',
                type: 'EVT'
              }
            });
            throw Error(error.response.data.error.message);
          }
        });
    },
    closeModal: function () {
      this.$store
        .dispatch('getSubscription')
        .then(() => {
          this.$parent.$emit('make-close');
        })
        .catch(() => {
          this.$parent.$emit('make-close');
        });

      this.$store.dispatch('getOrderHistory');
    },
    close() {
      this.$parent.$emit('make-close');
    }
  },
  mounted() {
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
    this.$store.dispatch('getOrderHistory');
    this.$store.dispatch('sendMpEvent', {
      event: 'Food Forward - View',
      properties: {
        location: this.$route.name,

        type: 'EVT'
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
